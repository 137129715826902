import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "enterupdateexit-animation"
    }}>{`Enter/update/exit animation`}</h1>
    <p>{`Now that you know how to use transitions, it's time to take it up a notch.
Enter/exit animations.`}</p>
    <p>{`Enter/exit animations are the most common use of transitions. They're what
happens when a new element enters or exits the picture. For example: in
visualizations like this famous
`}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=LLCF7vPanrY"
      }}>{`Nuclear Detonation Timeline`}</a>{` by
Isao Hashimoto. Each new Boom! flashes to look like an explosion.`}</p>
    <p>{`I don't know how Hashimoto did it, but with React & D3, you'd do it with
enter/exit transitions.`}</p>
    <p>{`Another favorite of mine is an old animated alphabet example by Mike
Bostock, the creator of D3, that's no longer online.`}</p>
    <p><img parentName="p" {...{
        "src": "https://d3nulzlctd9uky.cloudfront.net/blog/wp-content/uploads/2016/04/alphabet_2.gif",
        "alt": null
      }}></img></p>
    <p>{`That's what we're going to build: An animated alphabet. New letters fall down
and are green, updated letters move right or left, deleted letters are red and
fall down.`}</p>
    <p>{`You can play with a more advanced version
`}<a parentName="p" {...{
        "href": "https://swizec.github.io/react-d3-enter-exit-transitions/"
      }}>{`here`}</a>{`. Same
principle as the alphabet, but it animates what you type.`}</p>
    <p><img parentName="p" {...{
        "src": "https://raw.githubusercontent.com/Swizec/react-d3js-es6-ebook/2018-version/manuscript/resources/images/es6v2/typing-screenshot.png",
        "alt": "Typing animation screenshot"
      }}></img></p>
    <p>{`We're building the alphabet version because the
`}<a parentName="p" {...{
        "href": "https://swizec.com/blog/animated-string-diffing-with-react-and-d3/swizec/6952"
      }}>{`string diffing algorithm`}</a>{`
is a pain to explain. I learned that the hard way when giving workshops on
React and D3…`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn.staticaly.com/gh/hsribei/react-d3js-es6-ebook/024f7e0ae8547f5288f61bc442370f0f689d84eb/manuscript/resources/images/es6v2/string-diffing.jpg",
        "alt": "String diffing algorithm sketch"
      }}></img></p>
    <p>{`See?`}</p>
    <p>{`Easy on paper, but the code is long and weird. That, or I'm bad at implementing
it. Either way, it's too tangential to explain here. You can
`}<a parentName="p" {...{
        "href": "https://swizec.com/blog/animated-string-diffing-with-react-and-d3/swizec/6952"
      }}>{`read the article about it`}</a>{`.`}</p>
    <h2 {...{
      "id": "animated-alphabet"
    }}>{`Animated alphabet`}</h2>
    <p>{`Our goal is to render a random subset of the alphabet. Every time the set
updates, old letters transition out, new letters transition in, and updated
letters transition into a new position.`}</p>
    <p>{`We need two components:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`Alphabet`}</inlineCode>{`, which creates random lists of letters every 1.5 seconds, then
maps through them to render `}<inlineCode parentName="li">{`Letter`}</inlineCode>{` components`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Letter`}</inlineCode>{`, which renders an SVG text element and takes care of its own
enter/update/exit transitions`}</li>
    </ul>
    <p>{`You can see the full code on GitHub
`}<a parentName="p" {...{
        "href": "https://github.com/Swizec/react-d3-enter-exit-transitions/blob/master/src/components/Alphabet.jsx"
      }}>{`here`}</a>{`.`}</p>
    <h3 {...{
      "id": "project-setup"
    }}>{`Project setup`}</h3>
    <p>{`To get started you'll need a project. Start one with `}<inlineCode parentName="p">{`create-react-app`}</inlineCode>{`
or in CodeSandbox. Either works.`}</p>
    <p>{`You'll need a base App component that renders an SVG with an `}<inlineCode parentName="p">{`<Alphabet>`}</inlineCode>{`
child. Our component is self-contained so that's all you need.`}</p>
    <p>{`Something like this 👇`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Alphabet from './components/Alphabet\`;

const App = () => (
  <svg width="100%" height="600">
    <Alphabet x={32} y={300} />
  </svg>
)
`}</code></pre>
    <p>{`I follow the convention of putting components in a `}<inlineCode parentName="p">{`src/components`}</inlineCode>{` directory.
You don't have to.`}</p>
    <p>{`Remember to install dependencies: `}<inlineCode parentName="p">{`d3`}</inlineCode>{` and `}<inlineCode parentName="p">{`react-transition-group`}</inlineCode></p>
    <h3 {...{
      "id": "the-alphabet-component"
    }}>{`The Alphabet component`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`Alphabet`}</inlineCode>{` component holds a list of letters in component state and renders
a collection of `}<inlineCode parentName="p">{`Letter`}</inlineCode>{` components in a loop.`}</p>
    <p>{`We start with a skeleton like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/Alphabet.js
import React from "react";
import * as d3 from "d3";
import { TransitionGroup } from "react-transition-group";

import Letter from "./Letter";

class Alphabet extends React.Component {
    static letters = "abcdefghijklmnopqrstuvwxyz".split("");
    state = { alphabet: [] };

    componentDidMount() {
        // start interval
    }

    shuffleAlphabet = () => {
       // generate new alphabet
    };

    render() {
        let transform = \`translate(\${this.props.x}, \${this.props.y})\`;

        return (
            // spit out letters
        );
    }
}

export default Alphabet;
`}</code></pre>
    <p>{`We import dependencies and define the `}<inlineCode parentName="p">{`Alphabet`}</inlineCode>{` component. It keeps a list of
available letters in a static `}<inlineCode parentName="p">{`letters`}</inlineCode>{` property and an empty `}<inlineCode parentName="p">{`alphabet`}</inlineCode>{` in
component state.`}</p>
    <p>{`We'll start a `}<inlineCode parentName="p">{`d3.interval`}</inlineCode>{` on `}<inlineCode parentName="p">{`componentDidMount`}</inlineCode>{` and use `}<inlineCode parentName="p">{`shuffleAlphabet`}</inlineCode>{` to
generate alphabet subsets.`}</p>
    <p>{`To showcase enter-update-exit transitions, we create a new alphabet every
second and a half. Using `}<inlineCode parentName="p">{`d3.interval`}</inlineCode>{` lets us do that in a browser friendly
way.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/Alphabet/index.js
    componentDidMount() {
        d3.interval(this.shuffleAlphabet, 1500);
    }

    shuffleAlphabet = () => {
        const alphabet = d3
            .shuffle(Alphabet.letters)
            .slice(0, Math.floor(Math.random() * Alphabet.letters.length))
            .sort();

        this.setState({
            alphabet
        });
    };
`}</code></pre>
    <p>{`Think of this as our game loop: Change alphabet state in consistent time
intervals.`}</p>
    <p>{`We use `}<inlineCode parentName="p">{`d3.interval( //.., 1500)`}</inlineCode>{` to call `}<inlineCode parentName="p">{`shuffleAlphabet`}</inlineCode>{` every 1.5 seconds.
Same as `}<inlineCode parentName="p">{`setInterval`}</inlineCode>{`, but friendlier to batteries and CPUs because it pegs to
`}<inlineCode parentName="p">{`requestAnimationFrame`}</inlineCode>{`. On each period, we use `}<inlineCode parentName="p">{`shuffleAlphabet`}</inlineCode>{` to shuffle
available letters, slice out a random amount, sort them, and update component
state with `}<inlineCode parentName="p">{`setState`}</inlineCode>{`.`}</p>
    <p>{`This process ensures our alphabet is both random and in alphabetical order.`}</p>
    <p>{`Starting the interval in `}<inlineCode parentName="p">{`componentDidMount`}</inlineCode>{` ensures it only runs when our
Alphabet is on the page. In real life you should stop it on
`}<inlineCode parentName="p">{`componentWillUnmount`}</inlineCode>{`. Since this is a tiny experiment and we know
`}<inlineCode parentName="p">{`<Alphabet>`}</inlineCode>{` never unmounts without a page refresh, it's okay to skip that
step.`}</p>
    <h4 {...{
      "id": "declarative-render-for-enterexit-transitions"
    }}>{`Declarative render for enter/exit transitions`}</h4>
    <p>{`Our declarative enter/exit transitions start in the `}<inlineCode parentName="p">{`render`}</inlineCode>{` method.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/Alphabet/index.js
    render() {
        let transform = \`translate(\${this.props.x}, \${this.props.y})\`;

        return (
            <g transform={transform}>
                <TransitionGroup enter={true} exit={true} component="g">
                    {this.state.alphabet.map((d, i) => (
                        <Letter letter={d} index={i} key={d} />
                    ))}
                </TransitionGroup>
            </g>
        );
    }
`}</code></pre>
    <p>{`An SVG transformation moves our alphabet into the specified `}<inlineCode parentName="p">{`(x, y)`}</inlineCode>{` position.
We map through `}<inlineCode parentName="p">{`this.state.alphabet`}</inlineCode>{` inside a `}<inlineCode parentName="p">{`<TransitionGroup>`}</inlineCode>{` component and
render a `}<inlineCode parentName="p">{`<Letter>`}</inlineCode>{` component for every letter. Each `}<inlineCode parentName="p">{`Letter`}</inlineCode>{` gets a `}<inlineCode parentName="p">{`letter`}</inlineCode>{`
prop for the text, an `}<inlineCode parentName="p">{`index`}</inlineCode>{` prop to know where it stands, and a `}<inlineCode parentName="p">{`key`}</inlineCode>{` so
React can tell them apart.`}</p>
    <h4 {...{
      "id": "the-key-property"
    }}>{`The key property`}</h4>
    <p>{`The key property is how React identifies components. Pick wrong, and you're
gonna have a bad time. I spent many hours debugging and writing workarounds
before I realized that basing my key on the index was a Bad Move™. `}<em parentName="p">{`Obviously`}</em>{`,
you want the letter to stay constant in each component and the index to change.`}</p>
    <p>{`That's how x-axis transitions work.`}</p>
    <p>{`You move the letter into a specific place in the alphabet. You'll see what I
mean when we look at the `}<inlineCode parentName="p">{`Letter`}</inlineCode>{` component.`}</p>
    <h4 {...{
      "id": "transitiongroup"
    }}>{`TransitionGroup`}</h4>
    <p>{`React TransitionGroup gives us coordinated control over a set of transitionable
components. Each Letter is going to be a `}<inlineCode parentName="p">{`<Transition>`}</inlineCode>{`, you'll see.`}</p>
    <p>{`We need TransitionGroup to gain declarative control over the enter/exit cycle.
Transition components can handle transitions themselves, but they need an `}<inlineCode parentName="p">{`in`}</inlineCode>{`
prop to say whether they're in or out of the visualization.`}</p>
    <p>{`Flip from `}<inlineCode parentName="p">{`false`}</inlineCode>{` to `}<inlineCode parentName="p">{`true`}</inlineCode>{`, run an enter transition.`}</p>
    <p><inlineCode parentName="p">{`true`}</inlineCode>{` to `}<inlineCode parentName="p">{`false`}</inlineCode>{`, run an exit transition.`}</p>
    <p>{`We can make this change from within our component, of course. When responding
to user events for example. In our case we need that control to come from
outside based on which letters exist in the `}<inlineCode parentName="p">{`alphabet`}</inlineCode>{` array.`}</p>
    <p><inlineCode parentName="p">{`TransitionGroup`}</inlineCode>{` handles that for us. It automatically passes the correct `}<inlineCode parentName="p">{`in`}</inlineCode>{`
prop to its children based on who is and isn't being rendered.`}</p>
    <p>{`As an added bonus, we can use TransitionGroup to set a bunch of default
parameters for child Transitions. Whether to use `}<inlineCode parentName="p">{`enter`}</inlineCode>{` animations, `}<inlineCode parentName="p">{`exit`}</inlineCode>{`
animations, stuff like that. You can read
`}<a parentName="p" {...{
        "href": "https://github.com/reactjs/react-transition-group"
      }}>{`a full list in the docs`}</a>{`.`}</p>
    <h3 {...{
      "id": "the-letter-component"
    }}>{`The Letter component`}</h3>
    <p>{`We're ready for the component that can transition itself into and out of a
visualization. Without consumers having to worry about what's going on behind
the scenes 👌`}</p>
    <p>{`The skeleton for our `}<inlineCode parentName="p">{`Letter`}</inlineCode>{` component looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/Letter.js

import React from "react";
import * as d3 from "d3";
import Transition from "react-transition-group/Transition";

const ExitColor = "brown",
    UpdateColor = "#333",
    EnterColor = "green";

class Letter extends React.Component {
    defaultState = {
        y: -60,
        x: this.props.index * 32,
        color: EnterColor,
        fillOpacity: 1e-6
    };
    state = this.defaultState;
    letterRef = React.createRef();

    onEnter = () => {
        // Letter enters the visualization
    };

    onExit = () => {
        // Letter drops out transition
    };

    componentDidUpdate(prevProps, prevState) {
        // update transition
    }

    render() {
        const { x, y, fillOpacity, color } = this.state,
            { letter } = this.props;

        return (
            // render Transition with text
        );
    }
}

export default Letter;
`}</code></pre>
    <p>{`We start with some imports and define a `}<inlineCode parentName="p">{`Letter`}</inlineCode>{` component with a default
state. We keep `}<inlineCode parentName="p">{`defaultState`}</inlineCode>{` in a separate value because we're going to
manually reset state in some cases.`}</p>
    <p>{`A `}<inlineCode parentName="p">{`letterRef`}</inlineCode>{` helps us hand over control to D3 during transitions, the
`}<inlineCode parentName="p">{`onEnter`}</inlineCode>{` callback handles enter transitions, `}<inlineCode parentName="p">{`onExit`}</inlineCode>{` exit transitions, and
`}<inlineCode parentName="p">{`componentDidUpdate`}</inlineCode>{` update transitions. Render is where it call comes
together.`}</p>
    <p>{`Each of these transition methods is going to follow the same approach you
learned about in the swipe transition example. Render from state, transition
with D3, update state to match.`}</p>
    <p>{`You can make this component more flexible by moving the various magic numbers
we use into props. Default `}<inlineCode parentName="p">{`y`}</inlineCode>{` offset, transition duration, colors, stuff like
that. The world is your oyster my friend.`}</p>
    <h4 {...{
      "id": "onenter"
    }}>{`onEnter`}</h4>
    <p>{`We start with the enter transition in the `}<inlineCode parentName="p">{`onEnter`}</inlineCode>{` callback.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/Letter.js
onEnter = () => {
  // Letter is entering the visualization
  let node = d3.select(this.letterRef.current)

  node
    .transition()
    .duration(750)
    .ease(d3.easeCubicInOut)
    .attr("y", 0)
    .style("fill-opacity", 1)
    .on("end", () => {
      this.setState({
        y: 0,
        fillOpacity: 1,
        color: UpdateColor,
      })
    })
}
`}</code></pre>
    <p>{`We use `}<inlineCode parentName="p">{`d3.select`}</inlineCode>{` to grab our DOM node and take control with D3. Start a new
transition with `}<inlineCode parentName="p">{`.transition()`}</inlineCode>{`, specify a duration, an easing function, and
specify the changes. Vertical position moves to `}<inlineCode parentName="p">{`0`}</inlineCode>{`, opacity changes to `}<inlineCode parentName="p">{`1`}</inlineCode>{`.`}</p>
    <p>{`This creates a drop-in fade-in effect.`}</p>
    <p>{`When our transition ends, we update state with the new `}<inlineCode parentName="p">{`y`}</inlineCode>{` coordinate,
`}<inlineCode parentName="p">{`fillOpacity`}</inlineCode>{`, and `}<inlineCode parentName="p">{`color`}</inlineCode>{`.`}</p>
    <p>{`The result is an invisible letter that starts at -60px and moves into 0px and
full visibility over 750 milliseconds.`}</p>
    <h4 {...{
      "id": "onexit"
    }}>{`onExit`}</h4>
    <p>{`Our exit transition goes in the `}<inlineCode parentName="p">{`onExit`}</inlineCode>{` callback.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/Alphabet/
onExit = () => {
  // Letter is dropping out
  let node = d3.select(this.letterRef.current)

  node
    .style("fill", ExitColor)
    .transition(this.transition)
    .attr("y", 60)
    .style("fill-opacity", 1e-6)
    .on("end", () => this.setState(this.defaultState))
}
`}</code></pre>
    <p>{`Same as before, we take control of the DOM, run a transition, and update state
when we're done. We start with forcing our letter into a new color, then move
it 60px down, transition to invisible, and reset state.`}</p>
    <p>{`But why are we resetting state instead of updating to current reality?`}</p>
    <p>{`Our components never unmount.`}</p>
    <p>{`We avoid unmounts to keep transitions smoother. Instead of unmounting, we have
to reset state back to its default values.`}</p>
    <p>{`That moves the letter back into its enter state and ensures even re-used
letters drop down from the top. Took me a while to tinker that one out.`}</p>
    <h4 {...{
      "id": "render"
    }}>{`render`}</h4>
    <p>{`Hard work is done. Here's how you render:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/Alphabet/Letter.js
    render() {
        const { x, y, fillOpacity, color } = this.state,
            { letter } = this.props;

        return (
            <Transition
                in={this.props.in}
                unmountOnExit={false}
                timeout={750}
                onEnter={this.onEnter}
                onExit={this.onExit}
            >
                <text
                    dy=".35em"
                    x={x}
                    y={y}
                    style={{
                        fillOpacity: fillOpacity,
                        fill: color,
                        font: "bold 48px monospace"
                    }}
                    ref={this.letterRef}
                >
                    {letter}
                </text>
            </Transition>
        );
    }
`}</code></pre>
    <p>{`We render a `}<inlineCode parentName="p">{`Transition`}</inlineCode>{` element, which gives us the transition super powers we
need to run enter/exit transitions. Update transitions work on all React
components.`}</p>
    <p>{`The outside `}<inlineCode parentName="p">{`TransitionGroup`}</inlineCode>{` gives us the correct `}<inlineCode parentName="p">{`in`}</inlineCode>{` prop value, we just
have to pass it into `}<inlineCode parentName="p">{`Transition`}</inlineCode>{`. We disable `}<inlineCode parentName="p">{`unmountOnExit`}</inlineCode>{` to make
transitions smoother, define a `}<inlineCode parentName="p">{`timeout`}</inlineCode>{` which has to match what we're using in
our transitions, and define `}<inlineCode parentName="p">{`onEnter`}</inlineCode>{` and `}<inlineCode parentName="p">{`onExit`}</inlineCode>{` callbacks.`}</p>
    <p>{`There's a lot more to the API that we can use and you should check that out in
the docs. Docs don't go into detail on everything, but if you experiment I'm
sure you'll figure it out.`}</p>
    <p>{`Inside the transition we render an SVG `}<inlineCode parentName="p">{`<text>`}</inlineCode>{` element rendered at an `}<inlineCode parentName="p">{`(x, y)`}</inlineCode>{`
position with a `}<inlineCode parentName="p">{`color`}</inlineCode>{` and `}<inlineCode parentName="p">{`fillOpacity`}</inlineCode>{` style. It shows a single letter taken
from the `}<inlineCode parentName="p">{`letter`}</inlineCode>{` prop.`}</p>
    <h3 {...{
      "id": "thats-it-"
    }}>{`That's it 👍`}</h3>
    <p>{`Boom. We're done.`}</p>
    <p><img parentName="p" {...{
        "src": "https://raw.githubusercontent.com/Swizec/react-d3js-es6-ebook/2018-version/manuscript/resources/images/2018/alphabet-transitions.png",
        "alt": "Alphabet transitions are hard to screenshot"
      }}></img></p>
    <p>{`You can play with a more complex version of this example here:
`}<a parentName="p" {...{
        "href": "https://swizec.github.io/react-d3-enter-exit-transitions/"
      }}>{`https://swizec.github.io/react-d3-enter-exit-transitions/`}</a>{`.
Try typing different strings and see how the visualization reacts.`}</p>
    <p>{`The typing example uses the same Letter components to declaratively render its
string, but it drives the input through your typing instead of an automatic
shuffler.`}</p>
    <h3 {...{
      "id": "key-takeaways-for-transitions-are"
    }}>{`Key takeaways for transitions are:`}</h3>
    <ul>
      <li parentName="ul">{`use d3 for transitions`}</li>
      <li parentName="ul">{`use React to manage SVG elements`}</li>
      <li parentName="ul">{`use TransitionGroup and Transition for the enter/update/exit pattern`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      